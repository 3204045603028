<template>
    <div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title>Edit Inventory Session</b-card-title>
			<b-card-sub-title>Handles the update of inventory session details</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<b-form class="mt-4" @submit.stop.prevent="handleSubmit" novalidate>
                <div role="tablist">
                    <!-- PRIMARY INFORMATION -->
                    <b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion1 variant="primary" class="text-sm-left accordion-title">
								PRIMARY INFORMATION
							</b-btn>
						</b-card-header>
                        <b-collapse v-model="showFirstPane" id="accordion1" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-row class="my-2" no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Company" label-for="Company">
											<v-select name="Company" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="companyOptions" :reduce="(company) => company.value"
												v-model="selCompany" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Company')" class="help-block">
												{{ errors.first('Company') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Storage Location" label-for="Storage Location">
											<v-select name="Storage Location" class="style-chooser" label="text" placeholder=" - Please select - "
												:options="locationOptions" :reduce="(loc) => loc.value"
												v-model="selStorageLocation" v-validate="'selectRequired'">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Storage Location')" class="help-block">
												{{ errors.first('Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
                                </b-row>
                                <b-row class="my-2" no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4" v-if="!isScanner && !isViewer">
										<b-form-group label="Scanner" label-for="Scanner" description="Assigned user to scan for this session">
											<v-select class="style-chooser" name="Scanner" label="text" placeholder=" - Please select - "
												:options="userOptions" :reduce="(user) => user.value"
												v-model="selUser" v-validate="'selectRequired'"/>
											<span v-show="errors.has('Scanner')" class="help-block">
												{{ errors.first('Scanner') }}
											</span>
										</b-form-group>
									</b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Description" label-for="Description" description>
											<b-form-textarea name="Description" type="text" v-model="form.description"
												maxlength="200" v-validate="getValidationParam(true, remarksRegex)"
												:rows="3" placeholder="Description" />
											<span v-show="errors.has('Description')" class="help-block">
												{{ errors.first('Description') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <!-- ASSETS -->
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion2 variant="primary" class="text-sm-left accordion-title">
								ASSETS
							</b-btn>
						</b-card-header>
                        <b-collapse id="accordion2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div>
                                    <!-- ADD ASSETS -->
                                    <b-row no-gutters>
										<b-col sm="4" class="mr-4">
											<b-form-group label="Asset Type" label-for="assetType"
												description="Select asset type you want to include to in this session">
												<v-select name="assetType" class="style-chooser" label="text" placeholder=" - Please select - "
													:options="assetTypesOptions"
													:reduce="(assetType) => assetType.value" v-model="selAssetType">

													<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
															No results found for
															<em>
																<strong>{{ search }}</strong>
															</em>
														</template>
														<em :style="{ opacity: 0.5 }" v-else>
															Start typing to search for a user
														</em>
													</template>
												</v-select>
											</b-form-group>
										</b-col>
										<b-col lg="3" md="4" sm="4">
											<b-button variant="primary" class="add-button" @click="addAsset">
												Add Asset
											</b-button>
										</b-col>
									</b-row>

                                    <!-- SELECTED ASSETS -->
									<div>
										<b-row class="mt-4 mb-2">
											<b-col sm="8" class="md-left-text-sm-right">
												<strong>SELECTED ASSETS</strong>
											</b-col>
										</b-row>

										<b-row class="mb-2" v-show="form.assets.length > 0" no-gutters>
											<b-col sm="1" class="text-sm-center mr-1">
												<strong>#</strong>
											</b-col>
											<b-col sm="3" class="text-sm-left mr-1">
												<strong>Asset Type</strong>
											</b-col>
											<b-col sm="2" class="text-sm-center mr-1">
												<strong>Quantity</strong>
											</b-col>
											<b-col sm="1" class="text-sm-center mr-1">
												<strong>Action</strong>
											</b-col>
										</b-row>
										<b-row class="mb-2" v-for="(asset, key) in form.assets" :key="key" no-gutters>
											<b-col sm="1" class="text-sm-center mr-1">
												<strong>{{ key + 1 }}</strong>
											</b-col>
											<b-col sm="3" class="text-sm-left mr-1">
												{{ asset.assetType }}
											</b-col>
											<b-col sm="2" class="text-sm-left mr-1">
												<b-form-group :description="getAvailableStock(asset)">
													<b-form-input name="quantity" type="number" min="0"
														class="numFont text-right"
														v-model="selAssetTypes[asset.assetTypeId]" placeholder="0"
														v-validate="'required'" />
													<span v-show="errors.has('quantity')" class="help-block">
														{{ errors.first('quantity') }}
													</span>
												</b-form-group>
											</b-col>
											<b-col sm="1" class="text-sm-center mr-1">
												<b-button size="sm" v-b-tooltip.hover.top="'Remove Asset'"
													variant="danger" @click.stop="removeAsset(asset.assetTypeId)"
													class="mr-1">
													<em class="fa fa-trash"></em>
												</b-button>
											</b-col>
										</b-row>

										<b-row class="mb-2" v-show="form.assets.length === 0">
											<b-col sm="6" class="text-sm-left">
												<em>There is no selected assets yet.</em>
											</b-col>
										</b-row>
									</div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </b-form>

			<!-- Navigation Buttons -->
			<b-row>
				<b-col md="12" sm="12" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToInventorySessionMain" class="mr-2">
						Back
					</b-button>
					<b-button variant="primary" @click="handleOk" :disabled="isLoading">
						Save
					</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
        </b-card>
    </div>
</template>

<script>
// Utils
import { AssetPoolDistributionUtil } from '@/utils/assetPoolDistributionUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { InventorySessionUtil } from '@/utils/inventorySessionUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import sessionAPI from '@/api/inventorySessionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import _ from 'lodash';

export default {
	name: 'edit-inventory-session',
	components: {
		Loading
	},
	data() {
        return {
            form: { ...InventorySessionUtil.getDefaultSessionObj() },
			showFirstPane: true,

            // Dispatch Params
			params: {},

			allCompaniesObj: {},
            allStorageLocationsObj: {},
            allAssetTypesObj: {},
            allUsersObj: {},
            allCompanyAssetPoolsObj: {},

			companyOptions:[],
            locationOptions: [],
            userOptions: [],
            assetTypesOptions: [],

			selCompany:  { ...config.companyDefaultValue },
            selStorageLocation: { ...config.storageLocationDefaultValue },
            selUser: { ...config.userDefaultValue },
            selAssetType: { ...config.assetTypeDefaultValue },
            selAssetTypes: [],

            isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			isScanner: this.$store.getters.isScanner,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
            
            // Check for loader
			isLoading: false,
        }
    },
	watch: {
        allCompaniesObj: function () {
			this.companyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
		},

        selCompany: function () {
            this.onChangeCompany();
        }
    },
	computed: {
		remarksRegex() {
			return config.remarksRegex;
		},
        sessionId() {
			return this.form && this.form.sessionId ? this.form.sessionId : '-';
		}
    },
	mounted() {
		setTimeout(async () => {
			// Filter Access
			if (this.$store.getters.isViewer) {
				this.$router.push('/dashboard');
				this.$toaster.warning('You are not allowed to access this page.');
			}
			
			// show loading indicator
			this.isLoading = true;

			// init parameters
			this.params = this.$store.getters.inventorySessionParams;

			// Objects
			this.allCompaniesObj = this.params.allCompaniesObj;
			this.allStorageLocationsObj = this.params.allStorageLocationsObj;
            this.allAssetTypesObj = this.params.allAssetTypesObj;
			this.allUsersObj = this.params.allUsersObj;
            this.allCompanyAssetPoolsObj = this.params.allCompanyAssetPoolsObj;

			// reset options
			this.companyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
            this.locationOptions = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
            this.userOptions = DropDownItemsUtil.retrieveActiveUsers(this.allUsersObj);
            this.assetTypesOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

			this.onReset();

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
    methods: {
		getAvailableStock(asset){
			let remaining = asset && asset.totalRemaining ? asset.totalRemaining.toLocaleString() : '0';
			return 'Available Stock: ' + remaining;
		},
        getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},

		onChangeCompany() {
            if (this.selCompany.id === null) {
                this.resetStorageLocationOptions();
                this.resetUserOptions();
            } else {
				this.updateStorageLocationOptions();
				this.updateUserOptions();
                this.updateAssetTypeOptions();
            }
        },
        resetStorageLocationOptions() {
			this.locationOptions = [];
			this.locationOptions.push({
				value: { ...config.storageLocationDefaultValue },
				text: ' - Please select - ',
			});

			this.selStorageLocation = { ...config.storageLocationDefaultValue };
		},
        resetUserOptions() {
			this.userOptions = [];
			this.userOptions.push({
				value: { ...config.userDefaultValue },
				text: ' - Please select - ',
			});

			this.selUser = { ...config.userDefaultValue };
		},
		updateStorageLocationOptions() {
			this.locationOptions = DropDownItemsUtil.retrieveStorageLocationsByCompany(this.selCompany.id, this.allStorageLocationsObj);
		},
		updateUserOptions() {
			this.userOptions = DropDownItemsUtil.retrieveActiveUsersByCompany(this.allUsersObj, this.selCompany.id);
		},
        updateAssetTypeOptions() {
			let companyId = this.selCompany.id;
			this.assetTypesOptions = DropDownItemsUtil.retrieveAssetTypesByAccountability(this.allAssetTypesObj, this.allCompanyAssetPoolsObj, companyId, true);
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// show loading indicator
			this.isLoading = true;

			this.form = this.processSessionForm();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			let total = InventorySessionUtil.getTotalActualQuantity(this.form.assets);
			if (_.isEmpty(this.form.assets) || total === 0) {
				this.$toaster.warning('Session should have at least 1 asset type to inventory.');
				this.isLoading = false;
				return; 
			}
			
			await this.handleSubmit();
		},
		processSessionForm() {
			this.form.company = this.selCompany.name ? this.selCompany.name : '';
			this.form.companyId = this.selCompany.id ? this.selCompany.id : '';

			this.form.storageLocation = this.selStorageLocation.name ? this.selStorageLocation.name : '';
			this.form.storageLocationId  = this.selStorageLocation.id ? this.selStorageLocation.id : '';
			
			if (!this.isScanner && !this.isViewer) {
				this.form.user = this.selUser.name ? this.selUser.name : '';
				this.form.userId = this.selUser.id ? this.selUser.id : '';
			} else {
				this.form.user = this.loggedUser.firstName + ' ' + this.loggedUser.lastName;
				this.form.userId = this.loggedUser.id;
			}

			this.form.description = ValidationUtil.removeExcessWhiteSpace(this.form.description);

			for (const asset of this.form.assets) {
				asset.actualQuantity = this.selAssetTypes[asset.assetTypeId] ? parseInt(this.selAssetTypes[asset.assetTypeId]) : 0;
			}

			let currTimestamp = DateUtil.getCurrentTimestamp();
			this.form.dateUpdated = currTimestamp;
			this.form.updatedBy = this.loggedUser.id;

			return this.form;
		},

        async handleSubmit() {
			try {
				let { data } = await sessionAPI.saveInventorySession(
					this.form, 
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (!data.isSuccess) {
					this.$toaster.warning(data.message);
					// hide loading indicator
					this.isLoading = false;
					
				} else {
					this.$toaster.success(`Inventory Session "${this.sessionId}" was updated successfully.`);
					EventBus.$emit('onCloseSaveInventorySession', data.inventorySession);

					// reset the state and revert to inventory page
					this.returnToInventorySessionMain();
				}

			} catch (error) {
				this.$toaster.error(`Error updating inventory session. Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}			
		},
		returnToInventorySessionMain() {
			this.params.fromEditInventorySession = true;

			this.$store.dispatch('setInventorySessionParams', this.params);
			this.$store.dispatch('setCurrentInventorySession', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/inventory-session' });
			} else {
				this.$router.push({ path: '/admin/admin-inventory-session' });
			}
		},

		async addAsset() {
			if (this.selAssetType.id === null) {
				this.$toaster.warning('Please select an asset type to add');
				return;
			} else if (InventorySessionUtil.isExistingAssetType(this.form.assets, this.selAssetType.id)) {
				let assetTypeName = this.selAssetType.name;
				this.$toaster.warning(`Asset type "${assetTypeName}" already added.`);
				return;
			} else {
				this.form.assets.push(InventorySessionUtil.getDefaultInventoryAssetObj(this.selAssetType));
                await this.retrieveTotalAvailableAssets(this.selStorageLocation, this.form.assets);
			}
		},
        removeAsset(assetTypeId) {
			for (let i = 0; i < this.form.assets.length; i++) {
				let asset = this.form.assets[i];
				if (asset.assetTypeId === assetTypeId) {
					this.form.assets.splice(i, 1);
					delete this.selAssetTypes[assetTypeId];
				}
			}
		},
        async retrieveTotalAvailableAssets(locObj, assets) {
			try {
				// show loading indicator
				this.isLoading = true;

				await AssetPoolDistributionUtil.retrieveTotalAvailableAssets(locObj, assets);

			} catch (error) {
				console.error('Error retrieving asset pool:', error);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		async onReset() {
			// show loading indicator
			this.isLoading = true;

			/* Reset our form values */
			let session = this.$store.getters.currInventorySession;
			session = InventorySessionUtil.cleanupFields(session);

			this.form = { ...session };

			let company = this.allCompaniesObj[session.companyId];
			this.selCompany = DropDownItemsUtil.getCompanyItem(company);

            let storageLocation = this.allStorageLocationsObj[session.storageLocationId];
			this.selStorageLocation = DropDownItemsUtil.getStorageLocationItem(storageLocation);

			let user = this.allUsersObj[session.userId];
            this.selUser =  DropDownItemsUtil.getUserItem(user);

			this.form.assets = session.assets;
			await this.retrieveTotalAvailableAssets(this.selStorageLocation, this.form.assets);
			for (const asset of this.form.assets) {
				this.selAssetTypes[asset.assetTypeId] = asset.actualQuantity;
			}

			// reset validation
			this.$validator.reset();
			this.errors.clear();

			// hide loading indicator
			this.isLoading = false;
		},
    }
}
</script>

<style scoped>
.title {
	font-size: 22px !important;
}

.accordion-title {
	color: white !important;
}
</style>